input[type="file"]#file {
  top: -25px;
  margin: auto;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
#secondfile {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.txt-menu {
  text-shadow: 1px 1px 1px rgb(173, 173, 173);
}
.btn-float {
  margin: 5px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px;
  width:70px;
  background-color: rgb(215, 215, 215);
  text-decoration: none;
}
.btn-float > p{
  text-decoration: none;
  margin: 0px;
  font-size: small;
}