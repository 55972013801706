.login-skin {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  z-index: -1;
  /* background: rgb(18,255,0);
  background: -moz-linear-gradient(180deg, rgba(18,255,0,0) 0%, rgba(255, 0, 0, 0.5) 100%);
  background: -webkit-linear-gradient(180deg, rgba(18,255,0,0) 0%, rgba(255, 0, 0, 0.5) 100%);
  background: linear-gradient(180deg, rgba(18,255,0,0) 0%, rgba(255, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#12ff00",endColorstr="#ff0000",GradientType=1); */
}

.f-logo {
  background-color:rgba(255, 0, 0, 0.207);
}

.contenedor {
  z-index: 2;height: 100vh;
}

.centro {
  margin: auto auto;
}
.centro-logo {
  margin: auto auto;
}
.divmenu-login {
  border-radius: 60px 60px 20px 20px;
  box-shadow: -8px -2px 15px rgba(0, 0, 0, 0.453);
}

.divmenu-inj {
  border-radius: 12px;
}

.float-login {
  position: fixed;
  top: 5px;
  right: 5px;
  color: yellow;
  cursor: pointer;
}

.logoshadow {
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.413);
}

.cursor {
  cursor: pointer;
}