.react-daterange-picker__wrapper {
  border-radius: 4px !important;
  margin-top: 9px !important;
  padding: 5px !important;
  border: 1px solid rgb(184, 184, 184) !important;
}
.react-daterange-picker {
  width: 100%;
  margin-left: 9px;
}
.react-daterange-picker__inputGroup {
  padding: 0px !important;
  text-align: center !important;
}

@media (min-width: 990px) {
  .react-daterange-picker__wrapper {
    position: relative;
    left: -10px;
  }
}
