
.kiloin{
  border-radius: 10px;
  padding: 0px 3px;
  background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -60%;
  left: -10%;
}
.kiloout{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -60%;
  left: 55%;
}
.porinye{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -20%;
  left: 30%;
}
.golpesxmin{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -45%;
  left: 10%;
}
.pressss{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -85%;
  left: -20%;
}
.velcinta{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -40%;
  left: -55%;
}
.tempsalm{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(207, 207, 207);
  color: rgb(0, 0, 0);
  position: relative;
  top: -85%;
  left: 10%;
}
.tempsalmhigh{
  border-radius: 10px;
  padding: 0px 3px;
   background-color: rgb(255, 0, 0);
  color: rgb(255, 242, 0);
  position: relative;
  top: -85%;
  left: 10%;
}
.centro {
  margin: auto auto;
}
.hdr-nav {
  background-color: #BA2722;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.29);
}
.hoverClick {
  margin: auto auto;
  cursor: pointer;
  padding: 10px 0px;
  color: white;
}
.hoverClick:hover {
  color: rgb(251, 255, 0);
}
.txt-user {
  margin: auto auto;
  font-size: small;
  font-weight: 500;
  font-style: italic;
  color: rgb(255, 230, 0);
}
