.centro {
  margin: auto auto;
}
.hoverClick {
  cursor: pointer;
  padding: 10px 0px;
}

.hoverClick:hover {
  color: rgb(251, 255, 0);
}

.ultraWidh {
  height: 100vh;
}

@media (max-width: 767px) {
  .img-fluido {
    max-height: 200px;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .img-fluido {
    max-height: 200px;
    height: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .img-fluido {
    max-height: 250px;
    height: auto;
  }
}
@media (min-width: 1200px) {
  .img-fluido {
    max-height: 300px;
    height: auto;
  }
}