.centro {
  margin: auto auto;
}
.fullhd {
  height: 100vh;
}
.divmenu1 {
  border-radius: 12px;
  box-shadow: -5px -5px 8px rgba(0, 0, 0, 0.453);
}

.divmenu-inj {
  border-radius: 12px;
}

.float-login {
  position: fixed;
  top: 5px;
  right: 5px;
  color: yellow;
  cursor: pointer;
}