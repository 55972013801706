.hoverClick-sw {
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  background-color: #BA2722;
  padding: 3px 5px 3px 5px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: -3px 2px 3px rgba(0, 0, 0, 0.5);
}


.img-resp {
  width: 100%;
}

.table td, .table th {
  vertical-align: 0px !important;
}

.btn-float {
  position: relative;
  margin: 5px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 5px;
  width:70px;
  background-color: rgb(215, 215, 215);
  text-decoration: none;
}
.btn-float > p{
  text-decoration: none;
  margin: 0px;
  font-size: small;
}


.react-daterange-picker__calendar {
  z-index: 10 !important;
}
.table td, .table th {
  font-size: 12px !important;
}